import React, { Component } from "react";
import Layout from "../layout";
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import FeaturedImage from '../images/service.jpg';
import Hero from "../sections/hero";
import { Link } from 'gatsby';
import MobileDevelop from "../images/mobiledevelop.jpg";
import DigitalMarketing from "../images/digital.jpg";
import Cloud from "../images/cloud.jpg";
import Website from "../images/website.jpg";
import UiDesign from "../images/uidesign.jpg";
import Enterprise from "../images/enterprise.jpg";

class AppsPage extends Component {
  render() {
    return (
      <Layout>
        <Hero image={FeaturedImage}>
          <div className="sm:text-center lg:text-left">
            <h1 className="h1_tag">
              <span className="block xl:inline">We're tech partner of</span>{' '}
              <span className="block text-blue-600 xl:inline">Your Innovations</span>
            </h1>
            <p className="p_tag">
              Vinkas is a provider of IT consulting and software development services. We have helped organizations and companies improve business performance &amp; enhance their competitiveness.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="btn-lg text-white bg-blue-600 hover:bg-blue-700"
                >
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Call us
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  to="/contact"
                  className="btn-lg text-blue-700 bg-blue-100 hover:bg-blue-200"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Send message
                </Link>
              </div>
            </div>
          </div>
        </Hero>
        <div class="row bg-slate-100">
            <div className="container with-padding">
            <h4 className="service_heading">Services</h4>
            <p class="service_p_tag mt-4">We offer enterprise software solutions and custom software & application development. With the aim of designing and developing quality software products, we deliver innovative software applications that simplify various business problems. Our team with developers, designers, QA engineers, and project managers will be able to meet all your software project requirements.Our services are diversified into a web, mobile, and hybrid app development and providing a custom business solution from start-up to enterprises</p>
                <div class="grid grid-cols-1 gap-y-8 gap-x-8 md:grid-cols-2 lg:grid-cols-3 mt-8">
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full "
                                src={Website} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">Website development</h4>
                              </div>
                              <div class="mt-2">
                              <p class="service_p_tag">
                              Web development is the work involved in developing a website for the Internet (World Wide Web) or an intranet (a private network). Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.
                              </p>
                              </div>
                          </div>
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full  "
                                src={MobileDevelop} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">Mobile development</h4>
                              </div>
                              <div class="mt-2">
                              <p class=" service_p_tag">
                              Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones. These software applications are designed to run on mobile devices, such as a smartphone or tablet computer.
                              </p>
                              </div>
                          </div>
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full  "
                                src={Cloud} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">Cloud solutions</h4>
                              </div>
                              <div class="mt-2">
                              <p class=" service_p_tag">
                              Cloud solutions, also known as cloud computing or cloud services, deliver IT resources on demand over the Internet. Cloud service providers such Amazon Web Services, Microsoft Azure and Google Cloud Platform can deliver everything from applications to data centers on a pay-for-use basis to their subscribers.
                              </p>
                              </div>
                          </div>
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full  "
                                src={DigitalMarketing} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">Digital marketing</h4>
                              </div>
                              <div class="mt-2">
                              <p class=" service_p_tag">
                              Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services.
                              </p>
                              </div>
                          </div>
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full  "
                                src={UiDesign} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">UX-UI Design</h4>
                              </div>
                              <div class="mt-2">
                              <p class=" service_p_tag">
                              User interface (UI) design refers to the aesthetic elements by which people interact with a product, such as buttons, icons, menu bars, typography, colors, and more. User experience (UX) design refers to the experience a user has when interacting with a product.
                              </p>
                              </div>
                          </div>
                          <div class="flex-1">
                              <div>
                              <img
                                className="h-full w-full "
                                src={Enterprise} 
                                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                              />
                              </div>
                              <div class="mt-2">
                              <h4 class="service_head_tag">Enterprise Solutions</h4>
                              </div>
                              <div class="mt-2">
                              <p class=" service_p_tag">
                              Web application development is vital to improving the online performance of your business. We create custom-built solutions for a number of industries. Using cutting-edge technologies, such as Python/Django, AngularJS and PostgreSQL. We design and develop a high-quality application to satisfy your needs.
                              </p>
                              </div>
                          </div>
                 
                </div>
            </div>
        </div>

     </Layout>
    )
  }
}

export default AppsPage;
